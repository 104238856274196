<template>
  <div class="building-address">
    <div>
      <m-page-title :title="pageTitle.title" :describe="pageTitle.describe" />
      <div class="content">
        <div class="content-left">
          <el-form
            :model="addressForm"
            ref="addressForm"
            :rules="rules"
            label-position="top"
            size="medium"
            @submit.native.prevent
          >
            <el-form-item
              label="Building Name or Street Address"
              prop="address"
            >
              <el-autocomplete
                class="w-100"
                v-model="addressForm.address"
                :fetch-suggestions="querySearch"
                trigger-on-focus
                @select="handleSelect"
              >
                <el-image
                  slot="prefix"
                  :src="require('@/static/icon/Location.png')"
                  :style="{ width: '20px', height: '20px', margin: 'auto' }"
                />
                <template slot-scope="{ item }">
                  <div class="name">{{ item.value }}</div>
                  <span class="addr">
                    {{ item.street }},{{ item.city }},{{ item.province }},{{
                      item.postcode
                    }}
                  </span>
                </template>
              </el-autocomplete>
            </el-form-item>

            <div v-if="addressForm.addressCheck">
              <el-form-item label="Select apartment" prop="apartment">
                <el-select class="w-100" v-model="addressForm.apartment">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.value"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Select your status" prop="status">
                <div>
                  <el-radio v-model="addressForm.status" label="0"
                    >Tenant
                  </el-radio>
                  <el-radio v-model="addressForm.status" label="1"
                    >Owner</el-radio
                  >
                  <el-radio v-model="addressForm.status" label="2"
                    >Agent</el-radio
                  >
                </div>
                <div v-if="addressForm.status === '1'">
                  <el-radio v-model="addressForm.ownerStatus" label="0">
                    Residing
                  </el-radio>
                  <el-radio v-model="addressForm.ownerStatus" label="1">
                    Non-Residing
                  </el-radio>
                </div>
              </el-form-item>
            </div>

            <div class="line flex-row">
              <m-back />
              <el-button
                v-if="!addressForm.addressCheck"
                :disabled="!addressForm.address"
                type="primary"
                @click="addressForm.addressCheck = true"
              >
                Proceed to apartment selection
              </el-button>
              <el-button v-else type="primary" @click="proceedClick">
                Proceed
              </el-button>
            </div>
          </el-form>
        </div>
        <div v-if="addressForm.addressCheck" class="content-right">
          <img :src="addressForm.addressInfo.photo" alt="" class="img" />

          <div class="flex-row">
            <el-image
              class="address-icon"
              :src="require('@/static/icon/Location.png')"
            />
            <span class="address-txt">
              {{ buildingAddress }}
              <!--              {{ addressForm.addressInfo.name }} - -->
              <!--              {{ addressForm.addressInfo.street }},{{-->
              <!--                addressForm.addressInfo.city-->
              <!--              }},{{ addressForm.addressInfo.province }},{{-->
              <!--                addressForm.addressInfo.postcode-->
              <!--              }}-->
            </span>
          </div>
        </div>
      </div>
    </div>
    <m-dialog
      v-model="dialogStatus.show"
      mask
      unBack
      :image="addressForm.addressInfo.photo"
      :confirm-txt="dialogData.confirm"
      :cancel-txt="dialogData.cancel"
      :cancel-hide="dialogData.cancelHide"
      :address="buildingAddress"
      :title="dialogData.title"
      :content="dialogData.content"
      @cancel="cancelPopup"
      @confirm="confirmPopup"
    ></m-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "",
  components: {},
  data() {
    return {
      dialogStatus: {
        show: false,
        status: 0,
      },
      options: [],
      addressForm: {
        addressCheck: false, // UI状态 无其他意义
        address: "",
        addressInfo: {},
        apartment: null,
        status: undefined,
        ownerStatus: "",
      },
      rules: {
        address: [{ required: true, message: "Field cannot be empty" }],
        apartment: [{ required: true, message: "Field cannot be empty" }],
        status: [
          { required: true, message: "Status cannot be empty" },
          // { validator: this.ownerStatus },
        ],
      },
      buildingAddress: undefined,
    };
  },
  computed: {
    ...mapGetters("apartment", ["addressStr"]),
    // 页面标题
    pageTitle() {
      if (this.$route.query.type === "signup")
        return {
          title: "Building address",
          describe: "Step 3: Please add your building address",
        };
      else
        return {
          title: "Building address",
          describe: "Please add your building address",
        };
    },
    dialogData() {
      if (this.dialogStatus.status === 0) {
        return {
          confirm: "Yes",
          cancel: "No",
          title: "Book Move in",
          content: "Are you currently living in the building?",
        };
      } else if (this.dialogStatus.status === 1) {
        return {
          confirm: "Yes",
          cancel: "No",
          title: "Book Move in",
          content: "Are you ready to make a move in booking?",
        };
      } else {
        return {
          confirm: "Proceed",
          cancelHide: true,
          title: "Thanks for registering on Leon’s property.",
          content:
            "When you have your move-in date and time please log back into Leon’s property to set it up. ",
        };
      }
    },
  },
  created() {
    this.keyupSubmit();
  },
  methods: {
    ...mapMutations("bar", ["setSideBarStatus"]),
    ...mapMutations("apartment", ["setRoom", "setApartment"]),
    ...mapMutations("booking", ["setType"]),
    // enter事件
    keyupSubmit() {
      document.onkeydown = () => {
        const _key = window.event.keyCode;
        if (_key === 13) {
          if (this.addressForm.addressCheck) {
            if (this.dialogStatus.show) {
              this.confirmPopup();
            } else {
              this.proceedClick();
            }
          } else {
            if (this.addressForm.address.length > 0) {
              this.addressForm.addressCheck = true;
            }
          }
        }
      };
    },
    // 返回数据
    querySearch(queryString, cb) {
      if (queryString === "") {
        return false;
      }
      this.$axios
        .get(this.$api.seekUnits, {
          params: { search: queryString, pageSize: 20 },
        })
        .then((res) => {
          const list = [];
          if (res.data.list.length === 0) {
            this.$notify.warning("No matching buildings found");
            return false;
          }
          res.data.list.forEach((item) => {
            list.push({ value: item.unitName, ...item });
          });
          cb(list);
        });
    },
    // 搜索框选中
    handleSelect(e) {
      console.log(e);
      this.$set(this.addressForm, "apartment", null);
      this.$axios
        .get(this.$api.notAssociated(e.apartmentId), {
          params: { unitId: e.id },
        })
        .then((res) => {
          const list = [];
          res.data.list.forEach((item) => {
            list.push({
              value: `${item.floorName}${item.name}`,
              ...item,
            });
          });
          // 调整房号可选项
          this.options.splice(0, this.options.length, ...list);
        });
      this.addressForm.addressInfo = { ...e };
      this.buildingAddress = `${e.unitName}-${e.street},${e.city},${e.province},${e.postcode}`;
    },
    // proceed点击事件
    async proceedClick() {
      /*   this.setRoom(this.addressForm.apartment);
      this.setApartment(this.addressForm.addressInfo);
      this.dialogStatus.show = true;*/
      if (!(await this.$refs.addressForm.validate())) return false;

      if (this.addressForm.status === "1" && !this.addressForm.ownerStatus) {
        return this.$notify.warning("Please select your status.");
      }

      const ownerStatus = this.addressForm.ownerStatus;
      let isMoveIn =
        this.addressForm.status !== "1" ? null : ownerStatus === "0";

      const data = {
        ...this.addressForm.apartment,
        ...this.userStatus(this.addressForm.status),
        apartmentRoomId: this.addressForm.apartment.id,
        isMoveIn,
      };
      this.$axios
        .post(this.$api.userInRoom(data.apartmentId), data)
        .then((res) => {
          if (res.code === "1000") {
            this.$notify.success("New apartment added");
            if (ownerStatus == 1) {
              this.confirmPopup();
            } else {
              this.dialogStatus.show = true;
            }
            this.addressForm.addressInfo.id =
              this.addressForm.addressInfo.apartmentId;
            this.setRoom(this.addressForm.apartment);
            this.setApartment(this.addressForm.addressInfo);
          }
        });
    },
    // Select your status  (name - type -status)
    userStatus(i) {
      return [
        { type: 0, status: 1 }, // Tenant
        { type: 0, status: 0 }, // Owner
        { type: 1, status: null }, // Agent
      ][i];
    },
    // 弹框取消
    cancelPopup() {
      this.dialogStatus.show = false;
      setTimeout(() => {
        this.dialogStatus.status++;
        this.dialogStatus.show = true;
      }, 300);
    },

    // 弹框确认
    confirmPopup() {
      const type = this.$route.query.type;
      if (this.dialogStatus.status === 0 || this.dialogStatus.status === 2) {
        type === "signup" && this.setSideBarStatus(2);
        this.$router.push("/bookingRules");
      } else if (this.dialogStatus.status === 1) {
        type === "signup" && this.setSideBarStatus(3);
        this.setType(0); //move in
        this.$router.push("/dateAndTime");
      }
    },
    // 验证
    ownerStatus(rule, value, callback) {
      if (value === "1" && !this.addressForm.ownerStatus) {
        callback(new Error("Are you already living in the building?"));
      } else {
        callback();
      }
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
